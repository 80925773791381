<template>
  <div class="page-content">
    <div class="content">
      <div>
        <div class="title">{{ $t('1-jian-jie') }}</div>
        <p>
          {{ $t('11-huan-ying-fang-wen-appname-shang-dian-wang-zhan-dan-du-he-tong-cheng-wei-appname-wo-men-wo-men-de-huo-wo-men-de-appname-ren-zhen-lv-hang-shi-yong-yin-si-fa-lv-fa-gui-yin-si-fa-gui-ding-de-ze-ren-bing-cheng-nuo-zun-zhong-wo-men-appname-wang-zhan-he-yi-dong-ying-yong-cheng-xu-ping-tai-suo-you-yong-hu-de-yin-si-quan-he-gu-lv-wo-men-jiang-ping-tai-he-wo-men-zai-ping-tai-shang-miao-shu-de-wo-men-ti-gong-de-fu-wu-tong-cheng-wei-fu-wu-yong-hu-shi-zhi-wei-shi-yong-fu-wu-er-xiang-wo-men-zhu-ce-zhang-hu-de-yong-hu-bao-kuo-mai-jia-he-mai-jia-dan-du-he-tong-cheng-wei-yong-hu-nin-huo-nin-de-wo-men-ren-shi-dao-nin-wei-tuo-gei-wo-men-de-ge-ren-shu-ju-de-zhong-yao-xing-bing-xiang-xin-wo-men-you-ze-ren-tuo-shan-guan-li-bao-hu-he-chu-li-nin-de-ge-ren-shu-ju-ben-yin-si-zheng-ce-yin-si-zheng-ce-huo-zheng-ce-zhi-zai-bang-zhu-nin-le-jie-wo-men-ru-he-shou-ji-shi-yong-pi-lou-he-chu-li-nin-ti-gong-gei-wo-men-yi-ji-wo-men-yong-you-de-guan-yu-nin-de-ge-ren-shu-ju-wu-lun-shi-xian-zai-huan-shi-jiang-lai-yi-ji-zai-xiang-wo-men-ti-gong-nin-de-ren-he-ge-ren-shu-ju-zhi-qian-bang-zhu-nin-zuo-chu-ming-zhi-de-jue-ding-0', [appName, appName, appName, appName]) }} </p>
        <p>
          {{ $t('12-ge-ren-shu-ju-huo-ge-ren-shu-ju-shi-zhi-guan-yu-ke-yi-cong-gai-shu-ju-huo-cong-gai-shu-ju-he-zu-zhi-yi-jing-huo-ke-neng-ke-yi-fang-wen-de-qi-ta-xin-xi-zhong-shi-bie-de-ge-ren-de-shu-ju-wu-lun-shi-fou-zhen-shi-ge-ren-shu-ju-de-chang-jian-shi-li-bao-kuo-xing-ming-shen-fen-zheng-hao-ma-he-lian-xi-xin-xi-0') }} </p>
        <p>
          {{ $t('13-tong-guo-shi-yong-fu-wu-zai-wo-men-zhe-li-zhu-ce-zhang-hu-fang-wen-wo-men-de-ping-tai-huo-fang-wen-fu-wu-nin-cheng-ren-bing-tong-yi-nin-jie-shou-ben-yin-si-zheng-ce-zhong-gai-shu-de-zuo-fa-yao-qiu-he-zheng-ce-nin-te-ci-tong-yi-wo-men-an-zhao-ci-chu-suo-shu-shou-ji-shi-yong-pi-lou-he-chu-li-nin-de-ge-ren-shu-ju-ru-guo-nin-bu-tong-yi-an-zhao-ben-yin-si-zheng-ce-zhong-de-miao-shu-chu-li-nin-de-ge-ren-shu-ju-qing-bu-yao-shi-yong-wo-men-de-fu-wu-huo-fang-wen-wo-men-de-ping-tai-ru-guo-wo-men-geng-gai-wo-men-de-yin-si-zheng-ce-wo-men-jiang-tong-guo-zai-wo-men-de-ping-tai-shang-fa-bu-zhe-xie-geng-gai-huo-xiu-ding-hou-de-yin-si-zheng-ce-lai-tong-zhi-nin-wo-men-bao-liu-sui-shi-xiu-gai-ben-yin-si-zheng-ce-de-quan-li-zai-shi-yong-fa-lv-yun-xu-de-zui-da-fan-wei-nei-nin-ke-yi-shi-yong-ping-tai-shang-de-fu-wu-bao-kuo-xia-ding-dan-0') }} </p>
        <p>
          {{ $t('14-ben-zheng-ce-yu-shi-yong-yu-wo-men-shou-ji-cun-chu-shi-yong-pi-lou-he-chu-li-nin-de-ge-ren-shu-ju-de-qi-ta-tong-zhi-he-tong-tiao-kuan-he-tong-yi-tiao-kuan-yi-qi-shi-yong-chu-fei-wo-men-ling-you-ming-que-shuo-ming-fou-ze-wu-yi-tui-fan-zhe-xie-tong-zhi-huo-tiao-kuan-0') }} </p>
        <p>{{ $t('15-chu-ling-you-ming-que-gui-ding-wai-ben-zheng-ce-shi-yong-yu-shi-yong-ben-fu-wu-de-mai-mai-shuang-fang') }}</p>
      </div>
      <div>
        <h2 class="title">{{ $t('2-appname-he-shi-shou-ji-ge-ren-shu-ju-0', [appName]) }}</h2>
        <p>{{ $t('21-wo-men-jiang-ke-neng-hui-shou-ji-guan-yu-nin-de-ge-ren-shu-ju') }}</p>
        <ul>
          <li>{{ $t('dang-nin-fang-wen-he-fang-wen-wo-men-de-wang-zhan-shi') }}</li>
          <li>
            {{ $t('dang-nin-ti-jiao-ren-he-gong-zuo-shen-qing-huo-biao-ge-shi-bao-kuo-dan-bu-xian-yu-shen-qing-biao-ge-huo-yu-wo-men-de-ren-he-zhi-wei-fa-bu-xiang-guan-de-qi-ta-biao-ge') }} </li>
          <li>
            {{ $t('dang-nin-tong-guo-dian-hua-ke-neng-bei-lu-yin-xin-jian-chuan-zhen-mian-dui-mian-hui-yi-cai-fang-she-jiao-mei-ti-ping-tai-dian-zi-you-jian-huo-tong-guo-wang-zhan-yu-wo-men-hu-dong-shi-ta-bao-kuo-dan-bu-xian-yu-dang-nin-fang-wen-he-fang-wen-wo-men-de-wang-zhan-shi-wo-men-ke-neng-bu-shu-de-cookie-0') }} </li>
          <li>
            {{ $t('dang-zhao-pin-ren-yuan-jiu-ye-dai-li-huo-qi-ta-tui-jian-nin-jiu-ye-de-ge-fang-dai-biao-nin-ti-jiao-ren-he-cai-liao-shi-zai-zhe-zhong-qing-kuang-xia-wo-men-li-jie-nin-yi-xiang-xiang-wo-men-ti-gong-ci-lei-ge-ren-shu-ju-de-xiang-guan-di-san-fang-ti-gong-le-bi-yao-de-tong-yi') }} </li>
          <li>{{ $t('dang-nin-ti-gong-you-guan-nin-yu-wo-men-hu-dong-de-wen-jian-huo-xin-xi-shi') }}</li>
          <li>{{ $t('dang-nin-xiang-wo-men-ti-gong-fan-kui-huo-hui-fu-tiao-cha-shi') }}</li>
          <li>{{ $t('dang-nin-chu-yu-ren-he-yuan-yin-xiang-wo-men-ti-jiao-nin-de-ge-ren-shu-ju-shi') }}</li>
          <p>{{ $t('yi-shang-nei-rong-bu-wan-zheng-lie-chu-le-ke-neng-shou-ji-ge-ren-shu-ju-de-yi-xie-chang-jian-qing-kuang') }}</p>
        </ul>
        <p>
          {{ $t('22-wo-men-ke-neng-hui-cong-nin-wo-men-de-guan-lian-fang-di-san-fang-he-qi-ta-lai-yuan-shou-ji-guan-yu-nin-de-ge-ren-shu-ju-bao-kuo-dan-bu-xian-yu-ye-wu-he-zuo-huo-ban-ru-wu-liu-huo-zhi-fu-fu-wu-ti-gong-shang-xin-yong-huo-ping-ji-ji-gou-ying-xiao-fu-wu-ti-gong-shang-huo-he-zuo-huo-ban-tui-jian-ren-huo-zhong-cheng-du-ji-hua-wo-men-fu-wu-de-qi-ta-yong-hu-huo-gong-kai-ke-yong-huo-zheng-fu-shu-ju-yuan-0') }} </p>
        <p>
          {{ $t('23-zai-mou-xie-qing-kuang-xia-nin-ke-neng-hui-xiang-wo-men-ti-gong-qi-ta-ren-li-ru-nin-de-jia-ren-huo-peng-you-huo-nin-de-lian-xi-ren-lie-biao-zhong-de-ren-de-ge-ren-shu-ju-ru-guo-nin-xiang-wo-men-ti-gong-ta-men-de-ge-ren-shu-ju-nin-sheng-ming-bing-bao-zheng-nin-yi-huo-de-ta-men-de-tong-yi-gen-ju-ben-zheng-ce-chu-li-ta-men-de-ge-ren-shu-ju-0') }} </p>
      </div>
      <div>
        <h2 class="title">{{ $t('3-appname-shou-ji-na-xie-ge-ren-shu-ju-0', [appName]) }}</h2>
        <p>{{ $t('31-appname-ke-neng-shou-ji-de-ge-ren-shu-ju-bao-kuo-dan-bu-xian-yu-0', [appName]) }}</p>
        <ul>
          <li>{{ $t('xing-ming') }}</li>
          <li>{{ $t('dian-zi-you-jian-di-zhi') }}</li>
          <li>{{ $t('chu-sheng-ri-qi') }}</li>
          <li>{{ $t('jia-ting-di-zhi') }}</li>
          <li>{{ $t('dian-hua-hao-ma') }}</li>
          <li>{{ $t('xing-bie') }}</li>
          <li>{{ $t('tu-xiang-he-sheng-wu-shi-bie-shu-ju-bao-kuo-zhao-pian-yin-pin-huo-shi-pin-ji-lu-xing-bie') }}</li>
          <li>{{ $t('jiao-yu-jiu-ye-he-xin-zi-li-shi-ru-shi-yong') }}</li>
          <li>
            {{ $t('zai-nin-de-jiu-ye-shen-qing-he-xiang-guan-cai-liao-zhong-bao-han-he-shou-ji-de-qi-ta-xin-xi-bao-kuo-ti-gong-gei-wo-men-de-ren-he-tui-jian-xin-xi') }} </li>
          <li>
            {{ $t('ying-xiao-he-tong-xin-shu-ju-li-ru-nin-cong-wo-men-he-di-san-fang-jie-shou-ying-xiao-de-pian-hao-nin-de-tong-xin-pian-hao-yi-ji-yu-wo-men-wo-men-de-fu-wu-ti-gong-shang-he-qi-ta-di-san-fang-de-tong-xin-li-shi') }} </li>
          <li>{{ $t('di-li-huo-wei-zhi-shu-ju') }}</li>
          <li>
            {{ $t('dang-nin-fang-wen-fang-wen-wo-men-huo-wang-zhan-bing-yu-zhi-hu-dong-shi-ti-gong-de-guan-yu-nin-de-ren-he-qi-ta-xin-xi') }} </li>
          <li>{{ $t('hui-zong-you-guan-nin-can-yu-de-nei-rong-de-shu-ju') }}</li>
        </ul>
        <p>
          {{ $t('32-nin-tong-yi-bu-xiang-wo-men-ti-jiao-ren-he-bu-zhun-que-huo-ju-you-wu-dao-xing-de-xin-xi-bing-qie-nin-tong-yi-jiang-ci-lei-xin-xi-de-ren-he-bu-zhun-que-huo-bian-geng-tong-zhi-wo-men-wo-men-bao-liu-yao-qiu-ti-gong-jin-yi-bu-wen-jian-yi-yan-zheng-nin-ti-gong-de-ren-he-xin-xi-de-quan-li-0') }} </p>
        <p>
          {{ $t('33-ru-guo-nin-shi-yong-nin-de-she-jiao-mei-ti-zhang-hu-she-jiao-mei-ti-zhang-hu-zhu-ce-wei-wo-men-ping-tai-de-yong-hu-jiang-nin-de-appname-zhang-hu-lian-jie-dao-nin-de-she-jiao-mei-ti-zhang-hu-huo-shi-yong-ren-he-appname-wo-men-ke-neng-hui-fang-wen-nin-de-xin-xi-she-jiao-mei-ti-gong-neng-yi-gen-ju-ci-lei-ti-gong-shang-de-zheng-ce-zi-yuan-ti-gong-gei-nin-de-she-jiao-mei-ti-zhang-hu-ti-gong-shang-ren-he-ci-lei-ge-ren-shu-ju-jiang-shi-zhong-gen-ju-ben-zheng-ce-jin-hang-guan-li-he-shi-yong-0', [appName, appName]) }} </p>
        <p>
          {{ $t('34-ru-guo-nin-bu-xi-wang-wo-men-shou-ji-shang-shu-xin-xi-ge-ren-shu-ju-nin-ke-yi-tong-guo-shu-mian-tong-zhi-wo-men-de-shu-ju-bao-hu-guan-xuan-ze-tui-chu-you-guan-xuan-ze-tui-chu-de-geng-duo-xin-xi-qing-can-yue-xia-mian-biao-ti-wei-nin-ru-he-che-hui-nin-de-tong-yi-shan-chu-qing-qiu-fang-wen-huo-xiu-gai-nin-ti-gong-gei-wo-men-de-xin-xi-de-bu-fen-dan-qing-zhu-yi-xuan-ze-tui-chu-huo-che-xiao-nin-dui-wo-men-shou-ji-shi-yong-huo-chu-li-nin-de-ge-ren-shu-ju-de-tong-yi-ke-neng-hui-ying-xiang-nin-dui-fu-wu-he-ping-tai-de-shi-yong-li-ru-xuan-ze-tui-chu-wei-zhi-xin-xi-shou-ji-jiang-dao-zhi-qi-ji-yu-wei-zhi-de-gong-neng-bei-jin-yong-0') }} </p>
      </div>
      <div>
        <h2 class="title">{{ $t('4-qi-ta-zi-liao-de-shou-ji') }}</h2>
        <p>
          {{ $t('41-yu-da-duo-shu-wang-zhan-he-yi-dong-ying-yong-cheng-xu-yi-yang-nin-de-she-bei-fa-song-de-xin-xi-ke-neng-bao-kuo-nin-liu-lan-wo-men-wang-zhan-shi-you-wang-luo-fu-wu-qi-ji-lu-de-you-guan-nin-de-shu-ju-ta-tong-chang-bao-kuo-dan-bu-xian-yu-nin-de-hu-lian-wang-xie-yi-ip-di-zhi-ji-suan-ji-yi-dong-she-bei-cao-zuo-xi-tong-he-liu-lan-qi-lei-xing-yi-dong-she-bei-lei-xing-yi-dong-she-bei-de-te-xing-wei-yi-she-bei-biao-shi-fu-udid-huo-yi-dong-she-bei-biao-shi-fu-meid-dui-yu-nin-de-yi-dong-she-bei-tui-jian-wang-zhan-de-di-zhi-ru-guo-you-yi-ji-nin-zai-wo-men-wang-zhan-he-yi-dong-ying-yong-cheng-xu-shang-fang-wen-de-ye-mian-he-fang-wen-shi-jian-you-shi-huan-you-cookie-ke-yi-shi-yong-nin-de-liu-lan-qi-shou-xuan-xiang-jin-yong-bang-zhu-wang-zhan-ji-zhu-nin-shang-ci-fang-wen-zhe-xie-xin-xi-ye-bao-han-zai-ni-ming-tong-ji-zhong-yi-bian-wo-men-le-jie-fang-wen-zhe-ru-he-shi-yong-wo-men-de-wang-zhan-0') }} </p>
        <p>
          {{ $t('42-wo-men-de-yi-dong-ying-yong-cheng-xu-ke-neng-hui-shi-yong-gpswifi-deng-ji-shu-shou-ji-you-guan-nin-yi-dong-she-bei-wei-zhi-de-jing-que-xin-xi-wo-men-chu-yu-yi-ge-huo-duo-ge-mu-de-shou-ji-shi-yong-pi-lou-he-chu-li-ci-xin-xi-bao-kuo-dan-bu-xian-yu-nin-qing-qiu-de-ji-yu-wei-zhi-de-fu-wu-yi-bian-gen-ju-nin-de-wei-zhi-xiang-nin-ti-gong-xiang-guan-nei-rong-huo-yun-xu-nin-yu-qi-ta-yong-hu-gong-xiang-nin-de-wei-zhi-wo-men-yi-dong-ying-yong-cheng-xu-xia-de-bu-fen-fu-wu-dui-yu-da-duo-shu-yi-dong-she-bei-nin-ke-yi-tong-guo-nin-de-she-bei-she-zhi-che-xiao-nin-dui-wo-men-huo-qu-nin-wei-zhi-xin-xi-de-xu-ke-ru-guo-nin-dui-jin-yong-yi-dong-she-bei-de-ding-wei-fu-wu-you-ren-he-yi-wen-qing-sui-shi-lian-xi-nin-de-she-bei-fu-wu-ti-gong-shang-huo-zhi-zao-shang-0') }} </p>
        <p>
          {{ $t('43-zai-mou-xie-qing-kuang-xia-wo-men-ke-neng-hui-zai-dang-di-fa-lv-yun-xu-huo-zheng-de-nin-tong-yi-de-qing-kuang-xia-shou-ji-mou-xie-lei-xing-de-min-gan-xin-xi-li-ru-jian-kang-yi-liao-xin-xi-bao-kuo-can-ji-zhuang-kuang-gong-hui-hui-yuan-xin-xi-zong-jiao-zhong-zu-huo-zu-yi-yi-ji-fan-zui-xin-xi-ding-zui-he-zui-hang-ci-lei-xin-xi-de-shou-ji-he-shi-yong-jin-yong-yu-te-ding-mu-de-bing-zai-fa-lv-yun-xu-de-qing-kuang-xia-jin-hang-li-ru-jin-hang-bei-jing-tiao-cha-huo-zun-shou-shi-yong-de-zheng-ce-he-fa-lv-yi-wu-0') }} </p>
      </div>
      <div>
        <h2 class="title">5、Cookies</h2>
        <p>
          {{ $t('51-wo-men-huo-wo-men-de-shou-quan-fu-wu-ti-gong-shang-he-guang-gao-he-zuo-huo-ban-ke-neng-hui-ou-er-shi-yong-cookies-huo-qi-ta-gong-neng-lai-yun-xu-wo-men-huo-di-san-fang-shou-ji-huo-gong-xiang-you-guan-nin-shi-yong-wo-men-wang-zhan-huo-fu-wu-de-xin-xi-zhe-xie-gong-neng-you-zhu-yu-wo-men-gai-jin-wo-men-de-wang-zhan-he-wo-men-ti-gong-de-fu-wu-bing-shi-wo-men-de-guang-gao-he-zuo-huo-ban-he-wo-men-neng-gou-wei-nin-ti-gong-geng-duo-xiang-guan-nei-rong-bao-kuo-tong-guo-zai-ying-xiao-cookie-shi-cun-chu-zai-nin-de-ji-suan-ji-huo-yi-dong-she-bei-shang-de-biao-shi-fu-yong-yu-ji-lu-you-guan-ji-suan-ji-huo-she-bei-de-shu-ju-shi-yong-huo-fang-wen-wang-zhan-de-fang-shi-he-shi-jian-you-duo-shao-ren-yi-ji-wo-men-wang-zhan-nei-de-qi-ta-huo-dong-wo-men-ke-neng-hui-jiang-cookie-xin-xi-lian-jie-dao-ge-ren-shu-ju-bing-qie-cookie-huan-hui-lian-jie-dao-you-guan-nin-liu-lan-guo-na-xie-wang-ye-de-xin-xi-zhe-xie-xin-xi-zhi-zai-gen-ju-nin-de-xing-qu-ti-gong-nei-rong-shi-wo-men-de-di-san-fang-guang-gao-he-zuo-huo-ban-neng-gou-zai-hu-lian-wang-shang-de-wang-zhan-shang-tou-fang-guang-gao-jin-hang-shu-ju-fen-xi-bing-jian-kong-wang-zhan-de-shi-yong-qing-kuang-0') }} </p>
        <p>
          {{ $t('52-nin-ke-yi-tong-guo-zai-liu-lan-qi-shang-xuan-ze-shi-dang-de-she-zhi-lai-ju-jue-shi-yong-cookie-dan-shi-qing-zhu-yi-ru-guo-nin-zhe-yang-zuo-nin-ke-neng-wu-fa-shi-yong-he-fang-wen-wo-men-wang-zhan-de-quan-bu-gong-neng-qing-zhu-yi-ji-yu-cookie-de-xuan-ze-tui-chu-zai-yi-dong-ying-yong-cheng-xu-shang-ke-neng-wu-xiao-dan-shi-nin-ke-yi-an-zhao-android-he-ios-de-shuo-ming-xuan-ze-tui-chu-mou-xie-yi-dong-ying-yong-cheng-xu-shang-de-ge-xing-hua-guang-gao-0') }} </p>
      </div>
      <div>
        <h2 class="title">{{ $t('6-wo-men-ru-he-shi-yong-nin-ti-gong-gei-wo-men-de-xin-xi') }}</h2>
        <p>
          {{ $t('61-wo-men-ke-neng-chu-yu-yi-xia-yi-xiang-huo-duo-xiang-mu-de-shou-ji-shi-yong-pi-lou-he-chu-li-nin-de-ge-ren-shu-ju-0') }} </p>
        <ul>
          <li>{{ $t('kao-lv-bing-chu-li-nin-de-shen-qing') }}</li>
          <li>
            {{ $t('xiang-nin-ti-gong-nin-ke-neng-gan-xing-qu-de-gong-zuo-ji-hui-bao-kuo-yu-ji-qi-guan-lian-gong-si-huo-xiang-guan-gong-si-de-gong-zuo-ji-hui') }} </li>
          <li>{{ $t('guan-li-yun-ying-ti-gong-he-guan-li-wang-zhan-de-shi-yong') }}</li>
          <li>{{ $t('tong-guo-wei-nin-ti-gong-xiang-wo-men-ti-jiao-xin-xi-de-kuai-su-fang-fa-lai-ding-zhi-nin-de-ti-yan') }}</li>
          <li>{{ $t('zhi-hang-wo-men-de-fu-wu-tiao-kuan-huo-ren-he-shi-yong-de-zui-zhong-yong-hu-xu-ke-xie-yi') }}</li>
          <li>
            {{ $t('bao-hu-ren-shen-an-quan-yi-ji-wo-men-zi-ji-wo-men-de-yuan-gong-he-ta-ren-de-quan-li-cai-chan-huo-an-quan') }} </li>
          <li>{{ $t('yong-yu-shi-bie-he-yan-zheng') }}</li>
          <li>
            {{ $t('wei-hu-he-guan-li-ke-neng-bu-shi-xu-yao-de-ren-he-ruan-jian-geng-xin-he-qi-ta-geng-xin-he-zhi-chi-yi-que-bao-wang-zhan-de-shun-li-yun-hang') }} </li>
          <li>{{ $t('chu-li-huo-hui-ying-nin-huo-sheng-cheng-lai-zi-nin-de-ren-he-xun-wen') }}</li>
          <li>
            {{ $t('tong-guo-yu-yin-dian-hua-duan-xin-chuan-zhen-dian-zi-you-jian-he-you-zheng-you-jian-yu-nin-lian-xi-huo-gou-tong-you-guan-jiu-ye-ji-hui-de-xin-xi-yi-guan-li-wang-zhan-bing-ti-gong-yu-wang-zhan-guan-li-xiang-guan-de-xin-xi-nin-cheng-ren-bing-tong-yi-zhe-zhong-tong-xin-fang-shi-ke-neng-she-ji-tong-guo-zai-xin-feng-you-jian-bao-guo-de-wai-ke-shang-bao-han-xin-xi-lai-pi-lou-you-guan-nin-de-te-ding-ge-ren-shu-ju-0') }} </li>
          <li>
            {{ $t('jin-hang-yan-jiu-fen-xi-he-kai-fa-huo-dong-bao-kuo-dan-bu-xian-yu-shu-ju-fen-xi-tiao-cha-ji-shu-kai-fa-he-fen-xi-yi-fen-xi-nin-ru-he-shi-yong-wang-zhan-yi-gai-jin-he-zeng-qiang-wang-zhan') }} </li>
          <li>
            {{ $t('yun-xu-jin-hang-shen-ji-he-tiao-cha-yi-yan-zheng-wo-men-mu-biao-shou-zhong-de-gui-mo-he-gou-cheng-bing-le-jie-ta-men-dui-wang-zhan-de-ti-yan') }} </li>
          <li>
            {{ $t('yong-yu-shi-chang-ying-xiao-bing-zai-zhe-fang-mian-xiang-nin-fa-song-yu-appname-ji-qi-fu-shu-gong-si-huo-xiang-guan-gong-si-ke-neng-yong-you-de-gong-zuo-ji-hui-xiang-guan-de-xin-xi-he-cai-liao-wu-lun-ci-lei-gong-zuo-lie-biao-xian-zai-shi-fou-cun-zai-huo-jiang-lai-tong-guo-you-ji-huo-dian-zi-you-jian-chuang-jian-nin-ke-yi-sui-shi-shi-yong-ying-xiao-cai-liao-zhong-de-qu-xiao-ding-yue-gong-neng-qu-xiao-ding-yue-jie-shou-ying-xiao-xin-xi-wo-men-zhi-yao-wo-men-de-guan-lian-gong-si-ke-neng-hui-shi-yong-nin-de-lian-xi-xin-xi-lai-fa-song-xin-wen-tong-xun-huo-ying-xiao-cai-liao-0', [appName]) }} </li>
          <li>
            {{ $t('xiang-ying-fa-lv-cheng-xu-huo-zun-shou-ren-he-xiang-guan-si-fa-guan-xia-qu-de-ren-he-shi-yong-fa-lv-zheng-fu-huo-jian-guan-yao-qiu-huo-wo-men-zhen-cheng-di-ren-wei-ci-lei-pi-lou-shi-bi-yao-de-bao-kuo-dan-bu-xian-yu-man-zu-pi-lou-yao-qiu-ren-he-dui-appname-huo-qi-xiang-guan-gong-si-huo-fu-shu-gong-si-ju-you-yue-shu-li-de-fa-lv-yao-qiu-huo-dui-fa-lv-suo-pei-de-hui-ying-0', [appName]) }} </li>
          <li>{{ $t('wei-nei-bu-he-fa-ding-bao-gao-he-ji-lu-bao-cun-yao-qiu-ti-gong-tong-ji-shu-ju-he-yan-jiu') }}</li>
          <li>
            {{ $t('gen-ju-fa-lv-huo-jian-guan-yi-wu-huo-fa-lv-ke-neng-yao-qiu-huo-wo-men-ke-neng-yi-zhi-ding-de-feng-xian-guan-li-cheng-xu-jin-hang-jin-zhi-tiao-cha-huo-qi-ta-shai-xuan-huo-dong-bao-kuo-dan-bu-xian-yu-bei-jing-tiao-cha') }} </li>
          <li>
            {{ $t('zai-shi-yong-de-qing-kuang-xia-yu-nin-jian-li-gu-yong-guan-xi-bing-zhi-hang-he-guan-li-ren-he-xiang-guan-de-yi-wu-fu-li-he-zheng-ce') }} </li>
          <li>
            {{ $t('shen-he-wo-men-de-wang-zhan-fang-zhi-huo-tiao-cha-ren-he-qi-zha-fei-fa-huo-dong-yi-lou-huo-bu-dang-hang-wei-wu-lun-shi-fou-yu-nin-shi-yong-ben-wang-zhan-huo-yin-nin-yu-wo-men-de-guan-xi-er-chan-sheng-de-ren-he-qi-ta-shi-xiang-you-guan-yi-ji-shi-fou-dui-shang-shu-qing-kuang-you-ren-he-huai-yi-0') }} </li>
          <li>
            {{ $t('cun-chu-tuo-guan-he-bei-fen-wu-lun-shi-yong-yu-zai-nan-hui-fu-huan-shi-qi-ta-nin-de-ge-ren-shu-ju-wu-lun-shi-zai-nin-de-guan-xia-fan-wei-nei-huan-shi-zai-guan-xia-fan-wei-wai') }} </li>
          <li>{{ $t('wo-men-zai-zheng-de-nin-tong-yi-shi-tong-zhi-nin-de-ren-he-qi-ta-mu-de-tong-cheng-wei-mu-de') }}</li>
        </ul>
        <p>
          {{ $t('62-you-yu-wo-men-jiang-ke-neng-shou-ji-shi-yong-pi-lou-huo-chu-li-nin-de-ge-ren-shu-ju-de-mu-de-qu-jue-yu-shou-tou-de-qing-kuang-ci-lei-mu-de-ke-neng-bu-hui-chu-xian-zai-shang-mian-dan-shi-chu-fei-yin-si-fa-yun-xu-zai-wei-jing-nin-xu-ke-de-qing-kuang-xia-chu-li-shi-yong-shu-ju-fou-ze-wo-men-hui-zai-zheng-de-nin-tong-yi-hou-tong-zhi-nin-ci-lei-qi-ta-mu-de-0') }} </p>
        <p>
          {{ $t('63-nin-cheng-ren-tong-yi-bing-tong-yi-wo-men-ke-yi-fang-wen-bao-cun-he-pi-lou-nin-de-ge-ren-xin-xi-he-nei-rong-ru-guo-fa-lv-yao-qiu-huo-gen-ju-fa-yuan-ming-ling-huo-ren-he-dui-appname-huo-qi-guan-lian-gong-si-ju-you-guan-xia-quan-de-zheng-fu-huo-jian-guan-ji-gou-huo-zhen-cheng-di-xiang-xin-ci-lei-fang-wen-bao-hu-huo-pi-lou-dui-yu-yi-xia-fang-mian-shi-he-li-bi-yao-dea-zun-shou-fa-lv-cheng-xu-b-zun-shou-dui-appname-huo-qi-xiang-guan-fu-shu-gong-si-ju-you-guan-xia-quan-de-ren-he-zheng-fu-huo-jian-guan-ji-gou-de-yao-qiu-c-zhi-hang-wo-men-de-fu-wu-tiao-kuan-huo-ben-yin-si-zheng-ce-d-hui-ying-zhen-dui-appname-huo-qi-xiang-guan-fu-shu-gong-si-ti-chu-de-ren-he-wei-xie-huo-shi-ji-suo-pei-huo-ren-he-nei-rong-qin-fan-di-san-fang-quan-li-de-qi-ta-suo-pei-e-xiang-ying-nin-de-ke-hu-fu-wu-qing-qiu-f-bao-hu-appname-huo-qi-xiang-guan-guan-lian-gong-si-qi-yong-hu-he-gong-zhong-de-quan-li-cai-chan-huo-ren-shen-an-quan-0', [appName, appName, appName, appName]) }} </p>
      </div>
      <div>
        <h2 class="title">
          {{ $t('7-wo-men-bao-liu-na-xie-xin-xi-appname-ru-he-bao-hu-yong-hu-xin-xi-0', [appName]) }} </h2>
        <p>
          {{ $t('71-wo-men-shi-shi-ge-zhong-wu-li-guan-li-he-dian-zi-an-quan-cuo-shi-yi-que-bao-nin-de-ge-ren-shu-ju-zai-wo-men-xi-tong-shang-de-he-li-an-quan-yong-hu-ge-ren-shu-ju-bao-han-zai-an-quan-wang-luo-zhi-hou-zhi-you-shao-shu-yong-you-ci-lei-xi-tong-te-shu-fang-wen-quan-xian-de-yuan-gong-cai-neng-fang-wen-0') }} </p>
        <p>
          {{ $t('72-wo-men-jiang-gen-ju-yin-si-fa-he-qi-ta-shi-yong-fa-lv-bao-liu-ge-ren-shu-ju-bao-kuo-wei-shi-xian-shou-ji-shu-ju-de-mu-de-ti-gong-wo-men-de-fu-wu-jie-jue-jiu-fen-jian-li-fa-lv-fang-yu-zhui-qiu-he-fa-de-shang-ye-mu-de-zhi-hang-wo-men-de-xie-yi-suo-bi-xu-de-zun-shou-shi-yong-fa-lv-bao-kuo-bao-chi-zhun-que-de-jiu-ye-gong-zi-shui-shou-huo-qi-ta-ji-lu-ye-jiu-shi-shuo-dang-wo-men-he-li-que-ding-i-wo-men-yu-nin-bu-zai-you-guan-xi-ii-bao-liu-ci-lei-ge-ren-shu-ju-bu-zai-fu-he-bao-liu-gai-ge-ren-shu-ju-de-mu-de-shi-wo-men-jiang-xiao-hui-huo-ni-ming-hua-nin-de-ge-ren-shu-ju-ji-iii-chu-yu-ren-he-fa-lv-huo-shang-ye-mu-de-bu-zai-xu-yao-bao-liu-ru-guo-nin-che-hui-jiu-ye-shen-qing-ting-zhi-shi-yong-ben-wang-zhan-huo-zhe-nin-fang-wen-huo-shi-yong-ben-wang-zhan-de-xu-ke-bei-zhong-zhi-yi-ji-yi-qi-ta-fang-shi-zhong-zhi-nin-yu-wo-men-de-guan-xi-wo-men-ke-neng-hui-gen-ju-ben-yin-si-zheng-ce-he-wo-men-de-yi-wu-ji-xu-cun-chu-shi-yong-he-pi-lou-nin-de-ge-ren-shu-ju-gen-ju-yin-si-fa-gen-ju-shi-yong-fa-lv-wo-men-ke-neng-hui-an-quan-di-chu-li-nin-de-ge-ren-shu-ju-shu-bu-ling-hang-tong-zhi-0') }} </p>
      </div>
      <div>
        <h2 class="title">
          {{ $t('8-appname-shi-fou-xiang-wai-bu-ge-fang-pi-lou-cong-fang-wen-zhe-na-li-shou-ji-de-xin-xi-0', [appName]) }} </h2>
        <p>
          {{ $t('81-zai-kai-zhan-wo-men-de-ye-wu-guo-cheng-zhong-wo-men-jiang-ke-neng-xu-yao-shi-yong-chu-li-pi-lou-he-zhuan-yi-nin-de-ge-ren-shu-ju-gei-wo-men-de-di-san-fang-fu-wu-ti-gong-shang-dai-li-shang-he-wo-men-de-guan-lian-gong-si-huo-guan-lian-gong-si-ji-qi-ta-di-san-fang-zhe-xie-di-san-fang-ke-neng-wei-yu-xin-jia-po-jing-nei-huo-jing-wai-yong-yu-shang-shu-yi-xiang-huo-duo-xiang-mu-de-zhe-xie-di-san-fang-fu-wu-ti-gong-shang-dai-li-ren-guan-lian-gong-si-guan-lian-gong-si-he-qi-ta-di-san-fang-jiang-dai-biao-wo-men-huo-yi-qi-ta-fang-shi-chu-li-nin-de-ge-ren-shu-ju-yi-shi-xian-shang-shu-yi-ge-huo-duo-ge-mu-de-wo-men-nu-li-que-bao-di-san-fang-he-wo-men-de-guan-lian-gong-si-bao-hu-nin-de-ge-ren-shu-ju-mian-shou-wei-jing-shou-quan-de-fang-wen-shou-ji-shi-yong-pi-lou-chu-li-huo-lei-si-feng-xian-wo-men-jin-zai-shang-shu-mu-de-ge-ren-shu-ju-suo-xu-de-qi-xian-nei-bao-liu-nin-de-ge-ren-shu-ju-zhe-xie-di-san-fang-bao-kuo-dan-bu-xian-yu-0') }} </p>
        <ul>
          <li>{{ $t('wo-men-de-zi-gong-si-guan-lian-gong-si-he-guan-lian-gong-si') }}</li>
          <li>
            {{ $t('nin-zai-ping-tai-shang-yu-zhi-jiao-yi-huo-hu-dong-de-mai-jia-huo-mai-jia-huo-yu-nin-chu-yu-shang-shu-mu-de-shi-yong-fu-wu-xiang-guan-de-mai-jia-huo-mai-jia') }} </li>
          <li>{{ $t('chu-yu-shang-shu-yi-xiang-huo-duo-xiang-mu-de-shi-yong-wo-men-ping-tai-de-qi-ta-yong-hu') }}</li>
          <li>
            {{ $t('cheng-bao-shang-dai-li-shang-fu-wu-ti-gong-shang-he-wo-men-yong-lai-zhi-chi-wo-men-ye-wu-de-qi-ta-di-san-fang-zhe-xie-bao-kuo-dan-bu-xian-yu-wei-wo-men-ti-gong-hang-zheng-huo-qi-ta-fu-wu-de-ren-yuan-li-ru-you-ji-gong-si-wu-liu-fu-wu-ti-gong-shang-jin-rong-fu-wu-ti-gong-shang-guang-gao-he-ying-xiao-he-zuo-huo-ban-dian-xin-gong-si-xin-xi-ji-shu-gong-si-he-shu-ju-zhong-xin-0') }} </li>
          <li>
            {{ $t('dui-appname-ju-you-guan-xia-quan-huo-di-62-tiao-yun-xu-de-qi-ta-zheng-fu-huo-jian-guan-ji-gou-0', [appName]) }} </li>
          <li>
            {{ $t('zai-he-bing-bao-li-zhong-zu-jie-san-huo-qi-ta-chu-shou-huo-zhuan-rang-bu-fen-huo-quan-bu-appname-zi-chan-wu-lun-shi-zuo-wei-chi-xu-jing-ying-huan-shi-zuo-wei-po-chan-qing-suan-huo-lei-si-cheng-xu-de-yi-bu-fen-de-qing-kuang-xia-de-mai-fang-huo-qi-ta-ji-cheng-ren-qi-zhong-appname-chi-you-guan-yu-wo-men-de-fu-wu-yong-hu-de-ge-ren-shu-ju-shi-yi-xiang-zi-chan-zhuan-yi-huo-she-ji-appname-huo-qi-ren-he-fu-shu-gong-si-huo-xiang-guan-gong-si-de-shang-ye-zi-chan-jiao-yi-de-yi-fang-0', [appName, appName, appName]) }} </li>
          <li>
            {{ $t('wo-men-wei-yi-ge-huo-duo-ge-mu-de-xiang-qi-pi-lou-de-di-san-fang-zhe-xie-di-san-fang-you-wei-yi-ge-huo-duo-ge-mu-de-shou-ji-he-chu-li-nin-de-ge-ren-shu-ju') }} </li>
        </ul>
        <p>
          {{ $t('82-wo-men-ke-neng-hui-yu-guang-gao-he-zuo-huo-ban-he-di-san-fang-guang-gao-zai-ying-xiao-he-qi-ta-cheng-xu-ti-gong-shang-gong-xiang-yong-hu-xin-xi-bao-kuo-tong-ji-he-ren-kou-tong-ji-shu-ju-yi-ji-you-guan-ta-men-shi-yong-fu-wu-de-xin-xi-0') }} </p>
        <p>
          {{ $t('83-wei-mian-sheng-yi-wen-ru-guo-yin-si-fa-huo-qi-ta-shi-yong-fa-lv-yun-xu-wo-men-deng-zu-zhi-zai-wei-jing-nin-tong-yi-de-qing-kuang-xia-shou-ji-shi-yong-huo-pi-lou-nin-de-ge-ren-shu-ju-ze-gai-fa-lv-shou-yu-de-xu-ke-jiang-ji-xu-shi-yong-yu-shang-shu-yi-zhi-gen-ju-shi-yong-fa-lv-wo-men-ke-neng-chu-yu-gong-ren-de-fa-lv-yuan-yin-shi-yong-nin-de-ge-ren-shu-ju-bao-kuo-zun-shou-wo-men-de-fa-lv-yi-wu-lv-hang-wo-men-yu-nin-qian-ding-de-he-tong-yi-ji-he-fa-quan-yi-wo-men-shi-yong-zhe-xie-li-you-lai-di-xiao-dui-nin-de-shu-ju-bao-hu-quan-li-zao-cheng-de-ren-he-sun-hai-huo-zhe-zai-bi-yao-shi-yu-fa-lv-suo-pei-you-guan-0') }} </p>
        <p>
          {{ $t('84-di-san-fang-ke-neng-hui-fei-fa-lan-jie-huo-fang-wen-chuan-shu-dao-ben-wang-zhan-huo-bao-han-zai-ben-wang-zhan-shang-de-ge-ren-shu-ju-ji-shu-ke-neng-hui-fa-sheng-gu-zhang-huo-wu-fa-an-yu-qi-gong-zuo-huo-zhe-xin-xi-ke-neng-hui-yin-wo-men-de-guo-shi-er-bei-wei-jing-shou-quan-de-ren-fang-wen-lan-yong-huo-wu-yong-dan-shi-wo-men-jiang-an-zhao-yin-si-fa-de-yao-qiu-bu-shu-he-li-de-an-quan-cuo-shi-lai-bao-hu-nin-de-ge-ren-shu-ju-dan-shi-wu-fa-bao-zheng-jue-dui-an-quan-li-ru-dan-bu-xian-yu-dangeyi-he-lao-lian-de-hei-ke-fei-wo-men-de-guo-cuo-dao-zhi-wei-jing-shou-quan-de-pi-lou-shi-0') }} </p>
        <p>
          {{ $t('85-appname-yun-xu-nin-zai-appname-zhi-bo-gong-neng-zhong-fen-xiang-lai-zi-youtube-de-shi-pin-youtube-nei-rong-wei-ci-appname-shi-yong-le-youtube-ti-gong-de-youtube-api-fu-wu-shi-yong-gong-xiang-youtube-nei-rong-ji-biao-shi-nin-te-ci-tong-yi-shou-wei-yu-httpwwwgooglecompoliciesprivacy-de-google-yin-si-zheng-ce-de-yue-shu-0', [appName, appName, appName]) }} </p>
        <p>
          {{ $t('86-ru-appname-fu-wu-tiao-kuan-suo-shu-yong-hu-bao-kuo-ren-he-yuan-gong-dai-li-ren-dai-biao-huo-dai-biao-gai-yong-hu-huo-dai-biao-gai-yong-hu-de-ren-he-qi-ta-ren-tong-guo-shi-yong-fu-wu-yong-you-ling-yi-yong-hu-de-ge-ren-shu-ju-jie-shou-fang-te-ci-tong-yi-ta-men-jiang-zun-shou-shi-yong-yu-ren-he-ci-lei-shu-ju-de-suo-you-yin-si-fa-bao-kuo-ci-lei-shu-ju-de-ren-he-shou-ji-chu-li-cun-chu-huo-chuan-shu-0', [appName]) }} </p>
        <p>
          {{ $t('87-jin-guan-ci-chu-you-ren-he-gui-ding-mai-fang-bao-kuo-ren-he-gu-yuan-dai-li-ren-dai-biao-huo-dai-biao-gai-yong-hu-hang-shi-huo-dai-biao-gai-yong-hu-de-ren-he-qi-ta-ren-ying-zun-shou-yu-cong-appname-shou-dao-de-ren-he-mai-fang-xin-xi-xiang-guan-de-suo-you-shi-yong-yin-si-fa-0', [appName]) }} </p>
        <p>
          {{ $t('871-wei-jing-mai-fang-he-appname-shi-xian-shu-mian-tong-yi-bu-de-shi-yong-mai-fang-de-ge-ren-shu-ju-chu-fei-wei-hui-ying-mai-fang-de-xun-wen-yi-ji-hui-ying-chu-li-chu-li-huo-wan-cheng-jiao-yi-er-he-li-bi-yao-ying-bi-mian-shi-yong-ci-lei-xin-xi-lian-xi-appname-ping-tai-yi-wai-de-mai-jia-0', [appName, appName]) }} </p>
        <p>
          {{ $t('872-wei-jing-mai-fang-he-appname-shi-xian-shu-mian-tong-yi-appname-ping-tai-bu-hui-xiang-ren-he-wei-jing-shou-quan-de-di-san-fang-pi-lou-mai-fang-de-ge-ren-zi-liao-0', [appName, appName]) }} </p>
        <p>
          {{ $t('873-appname-ping-tai-jiang-cai-qu-chong-fen-de-an-quan-cuo-shi-bao-hu-qi-chi-you-de-mei-ge-appname-yong-hu-de-ge-ren-shu-ju-jin-zai-shang-shu-mu-de-he-yin-si-fa-gui-ding-de-bi-yao-qi-xian-nei-bao-liu-shu-ju-bing-shan-chu-huo-ying-appname-de-ren-he-yao-qiu-huo-zai-jiao-yi-wan-cheng-hou-de-he-li-shi-jian-nei-jiang-shu-ju-fan-hui-gei-appname-bing-zai-fa-sheng-ren-he-qian-zai-de-shu-ju-xie-lou-huo-ci-lei-yong-hu-shu-ju-de-qi-ta-diu-shi-shi-tong-zhi-appname-de-ge-ren-shu-ju-bao-hu-guan-0', [appName, appName, appName, appName, appName]) }} </p>
        <p>{{ $t('9-wo-men-dui-er-tong-de-zheng-ce') }}</p>
        <p>
          {{ $t('fu-wu-bu-shi-yong-yu-13-sui-yi-xia-de-er-tong-wo-men-bu-hui-you-yi-shou-ji-huo-wei-hu-13-sui-yi-xia-ren-he-ren-de-ge-ren-shu-ju-huo-fei-ge-ren-shen-fen-xin-xi-wo-men-ping-tai-de-ren-he-bu-fen-ye-bu-zhen-dui-13-sui-yi-xia-de-er-tong-wo-men-jiang-shan-chu-he-shan-chu-ren-he-ge-ren-xin-xi-wo-men-ren-wei-shu-ju-shi-you-13-sui-yi-xia-de-ren-he-er-tong-ti-jiao-de-zai-mou-xie-si-fa-guan-xia-qu-gen-ju-shi-yong-fa-lv-nian-ling-xian-zhi-ke-neng-geng-gao-zai-zhe-zhong-qing-kuang-xia-wo-men-zun-shou-shi-yong-de-nian-ling-xian-zhi-0') }} </p>
        <p>{{ $t('10-di-san-fang-shou-ji-de-xin-xi') }}</p>
        <p>
          {{ $t('101-wo-men-de-ping-tai-shi-yong-google-analytics-zhe-shi-google-incgoogle-ti-gong-de-yi-zhong-wang-luo-fen-xi-fu-wu-google-analytics-shi-yong-cookie-fang-zhi-zai-nin-she-bei-shang-de-wen-ben-wen-jian-bang-zhu-ping-tai-fen-xi-yong-hu-ru-he-shi-yong-ping-tai-you-cookie-sheng-cheng-de-guan-yu-nin-shi-yong-ping-tai-de-xin-xi-bao-kuo-nin-de-ip-di-zhi-jiang-bei-chuan-shu-bing-cun-chu-zai-gu-ge-wei-yu-mei-guo-de-fu-wu-qi-shang-gu-ge-jiang-shi-yong-zhe-xie-xin-xi-lai-ping-gu-nin-dui-ping-tai-de-shi-yong-wei-wang-zhan-yun-ying-shang-bian-zhi-wang-zhan-huo-dong-bao-gao-bing-ti-gong-yu-wang-zhan-huo-dong-he-hu-lian-wang-shi-yong-xiang-guan-de-qi-ta-fu-wu-gu-ge-ye-ke-neng-hui-zai-fa-lv-yao-qiu-huo-ci-lei-di-san-fang-dai-biao-gu-ge-chu-li-xin-xi-de-qing-kuang-xia-jiang-ci-xin-xi-chuan-shu-gei-di-san-fang-0') }} </p>
        <p>
          {{ $t('102-you-shi-di-san-fang-he-wo-men-ke-neng-hui-tong-guo-ping-tai-huo-fu-wu-ti-gong-ruan-jian-ying-yong-cheng-xu-xia-zai-gong-nin-shi-yong-zhe-xie-ying-yong-cheng-xu-ke-neng-hui-dan-du-fang-wen-bing-yun-xu-di-san-fang-cha-kan-nin-de-ge-ren-shen-fen-xin-xi-li-ru-nin-de-xing-ming-nin-de-yong-hu-id-nin-she-bei-de-ip-di-zhi-huo-qi-ta-xin-xi-li-ru-nin-zhi-qian-ke-neng-yi-jing-an-zhuang-huo-tong-guo-yi-xia-fang-shi-wei-nin-an-zhuang-de-ren-he-cookie-di-san-fang-ruan-jian-ying-yong-cheng-xu-huo-wang-zhan-ci-wai-zhe-xie-ying-yong-cheng-xu-ke-neng-hui-yao-qiu-nin-zhi-jie-xiang-di-san-fang-ti-gongewai-xin-xi-tong-guo-zhe-xie-ying-yong-cheng-xu-ti-gong-de-di-san-fang-chan-pin-huo-fu-wu-bu-you-appname-suo-you-huo-kong-zhi-wo-men-gu-li-nin-yue-du-ci-lei-di-san-fang-zai-qi-wang-zhan-huo-qi-ta-di-fang-fa-bu-de-tiao-kuan-he-qi-ta-zheng-ce-0', [appName]) }} </p>
        <p>{{ $t('11-guan-yu-an-quan-he-di-san-fang-wang-zhan-de-mian-ze-sheng-ming') }}</p>
        <p>
          {{ $t('111-wo-men-bu-bao-zheng-nin-zai-di-san-fang-wang-zhan-shang-ti-gong-de-ge-ren-shu-ju-he-qi-ta-xin-xi-de-an-quan-wo-men-shi-shi-ge-zhong-an-quan-cuo-shi-lai-wei-hu-wo-men-yong-you-huo-kong-zhi-de-nin-de-ge-ren-shu-ju-de-an-quan-nin-de-ge-ren-shu-ju-bao-han-zai-an-quan-wang-luo-zhi-hou-zhi-you-shao-shu-yong-you-ci-lei-xi-tong-te-shu-fang-wen-quan-xian-de-ren-cai-neng-fang-wen-wo-men-dui-ge-ren-shu-ju-bao-mi-dang-nin-fang-wen-nin-de-ge-ren-shu-ju-shi-wo-men-hui-ti-gong-yi-ge-an-quan-de-fu-wu-qi-nin-ti-gong-de-suo-you-ge-ren-shu-ju-huo-min-gan-xin-xi-du-bei-jia-mi-dao-wo-men-de-shu-ju-ku-zhong-bing-qie-zhi-neng-an-zhao-shang-shu-fang-shi-fang-wen-0') }} </p>
        <p>
          {{ $t('112-wei-le-ti-gong-zeng-zhi-wo-men-ke-neng-hui-xuan-ze-ge-zhong-di-san-fang-wang-zhan-lian-jie-dao-wang-zhan-bing-zai-wang-zhan-nei-gou-jian-zhe-xie-lian-jie-de-wang-zhan-you-dan-du-he-du-li-de-yin-si-zheng-ce-he-an-quan-an-pai-ji-shi-di-san-fang-yu-wo-men-you-guan-lian-wo-men-ye-wu-fa-kong-zhi-du-li-yu-wo-men-de-zhe-xie-lian-jie-wang-zhan-yin-si-he-shu-ju-shou-ji-shi-jian-wo-men-ke-neng-bu-hui-shou-dao-ci-lei-di-san-fang-wang-zhan-shou-ji-de-shu-ju-ji-shi-zai-wo-men-de-wang-zhan-shang-huo-tong-guo-wo-men-de-wang-zhan-ti-gong-0') }} </p>
        <p>
          {{ $t('113-yin-ci-wo-men-dui-zhe-xie-lian-jie-wang-zhan-de-nei-rong-an-quan-an-pai-huo-que-fa-an-quan-an-pai-he-huo-dong-bu-cheng-dan-ren-he-ze-ren-huo-yi-wu-zhe-xie-lian-jie-de-wang-zhan-zhi-shi-wei-le-nin-de-fang-bian-yin-ci-nin-xu-yao-zi-hang-cheng-dan-fang-wen-ta-men-de-feng-xian-wei-le-bao-hu-nin-de-xin-xi-wo-men-jian-yi-nin-zi-xi-yue-du-nin-fang-wen-de-suo-you-di-san-fang-fu-wu-de-yin-si-zheng-ce-jin-guan-ru-ci-wo-men-li-qiu-bao-hu-wo-men-wang-zhan-de-wan-zheng-xing-yi-ji-fang-zhi-zai-mei-ge-wang-zhan-shang-de-lian-jie-yin-ci-wo-men-huan-ying-dui-zhe-xie-lian-jie-wang-zhan-de-ren-he-fan-kui-bao-kuo-dan-bu-xian-yu-te-ding-lian-jie-shi-fou-you-xiao-0') }} </p>
        <p>{{ $t('12-appname-hui-jiang-nin-de-xin-xi-zhuan-yi-dao-hai-wai-ma-0', [appName]) }}</p>
        <p>
          {{ $t('nin-de-ge-ren-shu-ju-he-xin-xi-ke-neng-hui-bei-chuan-shu-dao-nin-suo-zai-guo-jia-di-qu-yi-wai-de-guo-jia-di-qu-bing-zai-zhe-xie-guo-jia-di-qu-zhi-wai-jin-hang-cun-chu-huo-chu-li-nin-de-da-bu-fen-ge-ren-shu-ju-jiang-zai-wo-men-de-fu-wu-qi-suo-zai-de-ying-guo-chu-li-wo-men-zhi-hui-gen-ju-yin-si-fa-jiang-nin-de-xin-xi-chuan-shu-dao-hai-wai-0') }} </p>
        <p>{{ $t('13-nin-ru-he-che-hui-tong-yi-qing-qiu-fang-wen-huo-geng-zheng-nin-ti-gong-gei-wo-men-de-xin-xi') }}</p>
        <p>{{ $t('131-che-hui-tong-yi') }}</p>
        <p>
          {{ $t('1311-nin-ke-yi-tong-guo-fa-song-dian-zi-you-jian-zhi-wo-men-de-ge-ren-shu-ju-bao-hu-guan-supporthuanyauscom-che-hui-nin-dui-shou-ji-shi-yong-he-pi-lou-wo-men-yong-you-huo-kong-zhi-de-nin-de-ge-ren-shu-ju-de-tong-yi-dan-shi-nin-che-hui-tong-yi-ke-neng-yi-wei-zhuo-wo-men-wu-fa-ji-xu-ping-gu-nin-de-shen-qing-he-nin-ti-gong-de-ren-he-xin-xi-0') }} </p>
        <p>
          {{ $t('1312-dang-nin-fen-xiang-youtube-nei-rong-shi-chu-le-gen-ju-di-1311-tiao-tong-guo-dian-zi-you-jian-che-hui-nin-dui-wo-men-de-tong-yi-wai-nin-huan-ke-yi-shi-yong-google-an-quan-she-zhi-ye-mian-httpssecuritygooglecomsettingssecuritypermissions-che-xiao-appname-dui-nin-de-ge-ren-shu-ju-de-fang-wen-quan-xian-0', [appName]) }} </p>
        <p>{{ $t('132-fang-wen-huo-geng-zheng-ge-ren-shu-ju-de-qing-qiu') }}</p>
        <p>
          {{ $t('1321-ru-guo-nin-you-wo-men-de-zhang-hu-nin-ke-yi-tong-guo-ben-ping-tai-de-zhang-hu-she-zhi-ye-mian-qin-zi-fang-wen-he-geng-zheng-wo-men-mu-qian-chi-you-huo-kong-zhi-de-nin-de-ge-ren-zi-liao-ru-guo-nin-mei-you-wo-men-de-zhang-hu-nin-ke-yi-tong-guo-xiang-wo-men-ti-jiao-shu-mian-qing-qiu-lai-qing-qiu-fang-wen-he-geng-zheng-wo-men-mu-qian-yong-you-huo-kong-zhi-de-nin-de-ge-ren-shu-ju-wo-men-xu-yao-nin-ti-gong-zu-gou-de-xin-xi-lai-que-ding-nin-de-shen-fen-he-qing-qiu-de-xing-zhi-yi-bian-neng-gou-chu-li-nin-de-qing-qiu-yin-ci-qing-tong-guo-supporthuanyauscom-jiang-nin-de-shu-mian-qing-qiu-fa-song-gei-wo-men-de-ge-ren-shu-ju-bao-hu-guan-0') }} </p>
        <p>
          {{ $t('1322-wo-men-ke-neng-hui-xiang-nin-shou-qu-he-li-de-fei-yong-lai-chu-li-nin-fang-wen-nin-de-ge-ren-shu-ju-de-qing-qiu-ru-guo-wo-men-xuan-ze-shou-fei-wo-men-jiang-xiang-nin-ti-gong-wo-men-jiang-shou-qu-de-fei-yong-de-shu-mian-gu-suan-qing-zhu-yi-chu-fei-nin-tong-yi-zhi-fu-fei-yong-fou-ze-wo-men-wu-xu-hui-fu-huo-chu-li-nin-de-fang-wen-qing-qiu-0') }} </p>
        <p>
          {{ $t('1323-wo-men-bao-liu-gen-ju-yin-si-fa-de-gui-ding-ju-jue-geng-zheng-nin-de-ge-ren-shu-ju-de-quan-li-gai-fa-yao-qiu-bing-shou-quan-zu-zhi-zai-suo-shu-qing-kuang-xia-ju-jue-geng-zheng-ge-ren-shu-ju-0') }} </p>
        <p>{{ $t('14-qi-ta-wen-ti-yi-lv-huo-tou-su') }}</p>
        <p>
          {{ $t('ru-guo-nin-dui-wo-men-de-yin-si-guan-li-you-ren-he-yi-wen-huo-yi-lv-qing-sui-shi-tong-guo-supporthuanyauscom-yu-wo-men-lian-xi-0') }} </p>
        <p>
          {{ $t('qing-bao-kuo-nin-de-xing-ming-lian-xi-xin-xi-he-qing-qiu-de-xing-zhi-yi-bian-wo-men-neng-gou-shi-dang-di-hui-fu-nin-de-lai-xin') }} </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    appName() {
      return this.$store.state.appName
    }
  }
}
</script>